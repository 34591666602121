:root {
  --primary-color: #1d1e25;
  --secondary-text-color: #C4C4C4;
  --tertiary-text-color: #979797;
  --primary-text-color: #E5E5E5;
  --accent-color: #ffffff;
  --skill-icon-brightness: 1;
  
  transition: transform 1s ease-in-out;
  
}

html {
  scroll-behavior: smooth;
  font-size: 1em;
  box-sizing: border-box;
}

.white-context {
  --primary-color: #dfdfdf;
  --secondary-text-color: #353535;
  --tertiary-text-color: #494949;
  --primary-text-color: #838383;
  --accent-color: #000000;
  --skill-icon-brightness: 0;
}

.App{
  --section-width: 65vw;
  --home-width: 80vw;

  padding: 0 20vw 0 20vw;

  background-color: var(--primary-color);
  color: var(--secondary-text-color);
  
  display: flex;
  flex-direction: column;
  text-align: center; 
  align-items: center;

  overflow: hidden;
}

p {
  color: var(--secondary-text-color);
}

h1 {
  color: var(--accent-color);
  font-size: 10em;
}

@keyframes typewriter {
  0% {
    width: 0%;
  }

  100% {
    width: 80%;
  }
}

h2 {
  font-size: 8em;
  opacity: 0.1;
  margin: 1em;
  width: 100%;
}

h3 {
  font-size: 2em;
  color: var(--accent-color);
}

h4 {
  font-size: 1.25em;
  line-height: 0;
  color: var(--accent-color)
}

ul {
  list-style-type: none;
}

a {
  color: var(--secondary-text-color);
  text-decoration: none;
}

li {
  font-size: 1.5em;
}


li:hover, a:hover {
  animation: flash 1.5s alternate infinite ease-in-out;
}

.offset-border {
  box-shadow: 10px -10px 0 0px var(--primary-color), 10px -10px 0 1px var(--accent-color);
}

.scroll-link {
  animation: flash 1.5s alternate infinite ease-in-out;
  font-size: 1.5em;
  overflow: hidden;
  animation: typewriter 1s steps(10, end);
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
}

.work-info-grid {
  display: flex;
  flex-flow: row wrap;
  height: 60vh;
  padding-top: 1em;
  align-content: start;
}

#nav-bar {
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  width: 100%;
  border-bottom: 1px var(--accent-color) solid;
  background: rgba(0,0,0,0.25);
  align-items: center;
  z-index: 100;
  font-size: .65em;
  padding: 1vh 0 1vh 0;

  
}



.nav-bar-hidden {
  top: -10vh;
}

#nav {
  width: 80%;
  display: flex;
  justify-content: space-around;
  
}

#nav ul {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  
}

#nav li {
  padding: 0 1em 0 1em;
  color: var(--secondary-text-color)
  
}

.socials {
  grid-area: socials;
  min-width: 20em;
  z-index: 1;
  align-self: center;
}

.side-info {
  position: fixed;
 
  bottom: 10vh;
  transform: rotate(90deg); 
  animation: side-info-in 1s ease-in-out;
}

@keyframes side-info-in {
  0% {
    bottom: 0;
  }
  100% {
    bottom: 10vh;
  }
} 

.side-info ul {
  display: flex;
  column-gap: 2em;
}

.side-info ul::after {
  content: ' ';
  position: relative;
  display: inline-block;
  height: 1px;
  margin: 1em;
  width: 50vh;
  background: var(--primary-text-color);
}

.icon {
  transform: rotate(-90deg);
}

.socials ul {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: start;
  list-style-type: none;
}

.socials li {
  padding: 1em;
  justify-self: center;
}

#home {
  display: grid;
  grid-template-columns: 5% 5% 80% 10%;
  grid-template-rows: 10% 80% 10%;
  grid-template-areas: 
    "e      e  nav  lightbulb"
    "e      e  title  sidebar"
    "scrolldown blank      socials  socials"
  ;  

  height: 100vh;
  width: 80vw;

  align-items: center;
  padding-bottom: 20em;
  
}

.sidebar {
  flex-direction: column;
  background-color: var(--accent-color);
  height: 15em;
  width: 2em;
  grid-area: sidebar;
  justify-self: end;
  align-self: start;
  margin-top: 15vh;
}

.sidebar-box {
  height: 20%;
  width: 100%;
}

.arrow {
  
  position: fixed;
  top: 95vh;
  background-color: var(--accent-color);
  width: 1px;
  align-self: start;
  transition: transform 1s;
  animation: float 1s alternate infinite, arrow-in 1s ease-in-out;
}

.arrow::before {
  content: '';
  color: rgba(255,255,255,0.1);
  display: block;
  position: relative;
  top: 100%;
  right: 0.35em;
  width: 0; 
  height: 0; 
  border-left: 0.4em solid transparent;
  border-right: 0.4em solid transparent;
  border-top: 0.6em solid var(--accent-color);
  border-radius: 0.2em;
}

@keyframes arrow-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
} 



.header-line {
  content:'';
  margin-top: 1em;
  margin-bottom: 3em;
  width: 5vw;
  height: .5em;
  background: var(--accent-color);
  border-radius: 1em;
}

@keyframes flash {
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@keyframes float {
  0% {
    padding-top: 0;
  }
  100% {
    padding-top: .1em;
  }
}



#light-bulb {
  position: absolute;
  top:0;
  right: 20vw;
  background-color: var(--tertiary-text-color);
  height: 25vh;
  width: 1px;
  animation: rotate 2s alternate infinite ease-in-out, light-bulb-in 1s ease-in-out;
  transform-origin: top;
  cursor: pointer;
}


@keyframes rotate {
  0% {
    transform: rotate(-0.5deg);
  }
  100% {
    transform: rotate(0.5deg);
  }
}

#light-bulb::before {
  content: '';
  display: block;
  position: relative;
  top: 100%;
  right: .28em;
  width: .7em; 
  height: 1.25em; 
  background: var(--secondary-text-color);
  border-radius: 1em;

  animation: rotate 1s alternate infinite ease-in-out;
}

#light-bulb::after {
  content: '';
  display: block;
  position: relative;
  top: 98%;
  right: .66em;
  width: 1.5em; 
  height: 1.5em; 
  background: var(--primary-text-color);
  border-radius: 1em;
  box-shadow: 0px 0px 50px white;
}

#light-bulb:hover {
  padding-top: 0.5em;
  transition: padding-top 0.2s ease-in-out;
}

#title {
  grid-area: title;
  display: flex;
  flex-direction: column;
  flex-wrap: none;
  width: 80%;
  justify-self: center;
 /*  border: 1px var(--accent-color) solid; */
}






.flex-container {
  display: flex;
  width: 100%;
  align-content: start;
}

#work {
  display: flex;
  flex-flow: row wrap;

  font-size: 1em;
  height: max-content;

  justify-items: center;
  align-content: start;
  text-align: start;
  margin: 0 0 20vh 0;

  
}

#work h2 {
  text-align: center;
}



.active {
  color: var(--accent-color);
  border: 1px var(--accent-color) solid;
  border-radius: .25em;
  animation: activein 1s alternate;
  background: rgba(255,255,255,0.1);
}

.active::before {
  content: '▶';
  position: relative;
  padding-left: 1em;
  animation: point 0.5s alternate infinite;
}

/* .active:hover {
  padding: 0.6em !important;
  
}
.active::before {
  content: '';
  display: inline-block;
  position: relative;
  top: 0.12em;
  left: -2em; 
  width: 0; 
  height: 0; 
  border-left: 0.4em solid transparent;
  border-right: 0.4em solid transparent;
  border-top: 1em solid var(--accent-color);
  border-radius: 0.2em;
  transform: rotate(-90deg);

  animation: point 0.2s alternate infinite;
} */

@keyframes point {
  0% {
    left: -2em;
  }

  100% {
    left: -2.1em;
  }
}


/* #work-selector li:hover::before { 
  content: '';
  display: inline-block;
  position: relative;
  top: 100%;
  width: 0; 
  height: 0; 
  border-left: 0.4em solid transparent;
  border-right: 0.4em solid transparent;
  border-top: 1em solid var(--accent-color);
  border-radius: 0.2em;
  transform: rotate(-90deg);
} */


#work-display {
  display: flex;
  height: 80vh;
}

#work-info {
  display: flex;
  flex-wrap: wrap;
  text-align: start;

  width: 100%;
  
  align-content: start;
  justify-content: start;
  /* background: rgba(255,255,255,0.025);
  border-radius: 1em;
  padding: 5em; */


}

#work-info ul {
  padding-bottom: 1em;
  border-left: 1px var(--primary-text-color) solid;
}

#work-info li:hover {
  animation: none;
}

#work-info ul ul {
  border: none; 
}


#work-info-desc li {
  font-size: 1em;
}

#work-info-desc ul {
  padding-left: 1em;
}

#work-info-desc ul li {
  padding-left: 1em;
}

#work-info-img {
  grid-area: pic;
  display: flex;
  justify-content: center;
  flex-flow: row wrap;

}

#work-info-img img {
  width: 100%;
  height: auto;
  filter: grayscale(1) brightness(0.8);
  padding: 1em;
  border: 1px var(--accent-color) solid;
  border-radius: 2px;
  /* box-shadow: -4px 4px 0px var(--accent-color); */
  /* box-shadow: -10px -10px 0 0px var(--primary-color), -10px -10px 0 1px var(--accent-color); */
 
  transition: filter .5s ease-in-out, transform .5s ease-in-out, box-shadow .5s ease-in-out;

  transform: perspective(1500px) rotateY(20deg);  
}

@keyframes idle-work-info-img {
  0%{
    transform: perspective(1500px) rotateY(-20deg);
  }
  100%{
    transform: perspective(1500px) rotateY(20deg);
  }
}

#work-info-img img:hover {
  filter: none;
  transform: perspective(1500px) rotateY(15deg);
  box-shadow: -10px 10px 0 0px var(--primary-color), -10px 10px 0 1px var(--accent-color);
}

#work-info-desc ul {
  height: min-content;
}

#work-info-desc ul li::marker  {
  content: "🠖";
}

#work-selector {
  display: flex;
  flex-flow: row wrap;
}

#work-selector ul {
  padding: 0 2.5em 0 0em;
  height: max-content;
  border: none;
  color: var(--tertiary-text-color);
  
}


#work-selector li {
  width: 100%;
  margin: 0.5em 0 .5em 0;
  padding: .5em 1em .5em 2em;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}

#work-selector li:hover {
  background: rgba(255,255,255,0.1);
}

.work-role {
  color: var(--tertiary-text-color);
}

.work-heading {
  margin-bottom: 2em;
  border-bottom: 1px var(--accent-color) solid;
  padding-bottom: 1em;
}

#selector {
  display: flex;
  justify-content: center;
  align-content: center;
  font-size: 1em;
  cursor: pointer;
  height: auto;

  z-index: 2;
  padding-bottom: 2em;
}

#selector button {
  text-align: center; 
  align-self: center;
  cursor: pointer;
  border: none;
  border: 1px var(--primary-text-color) solid;
  color: var(--accent-color);

  background: transparent;
  width: 3em;
  height: 2em;
  font-size: 2em;
  border-radius: .25em;
  transition: all .5s ease-in-out;

}

button:hover {
  animation: flash 1s alternate infinite;
}


@keyframes light-bulb-in {
  0% {
    height: 0%;
  }
  100% {
    height: 25vh;
  }
}

#about {
  display: flex;
  flex-flow: row wrap;
  width: 80%;
  row-gap: 2vh;
  padding-bottom: 30vh;
  justify-items: start; 
  text-align: start; 
  
}

#about h2 {
  justify-self: center;
  align-self: center;
  text-align: center;
}

#skill-wrapper {
  text-align: start; 
  width: 100%;
  padding: 0em;

  
}

#skill-icons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 4em;

  column-gap: 2em;
  row-gap: 2em;
  justify-content: start;
  justify-self: start;
  align-self: start;

  filter: brightness(var(--skill-icon-brightness));

  padding: 1em;
}

#skill-icons li {
  display: flex;
  height: 4em;
  width: 4em;
  justify-content: center;
}

#skill-icons li:hover {
  animation: none;
}

#skill-icons li img {
  display: flex;
  height: 100%;
  width: auto;
}

#bio {
  padding: 0em 0em 0em 5em;
  grid-area: content;
}

#bio-text {
  margin-top: 2em;
}

#bio-pic-container p {
  
  opacity: 0.25;
  line-height: 0;
  font-style: italic;
}

#bio-pic-container {
  display: flex;
 grid-area: pic;
 width: 100%;
 

 justify-content: center;
 align-content: center;
 align-self: center;
}

@keyframes idle-bio-img {
  0%{
    transform: perspective(1500px) rotateY(-25deg);
  }
  100%{
    transform: perspective(1500px) rotateY(-15deg);
  }
}


#bio-pic {
  align-self: center;
  justify-self: center;
  width: 50%;

  /* transform: rotate(45deg); */
  border-radius: 1em;
  /* animation: float 1s alternate infinite; */

}

#bio-pic img {
  
  width: 80%;
  padding: 2em;
  height: auto;
  object-fit: cover;
  border-radius: 2px;
  margin: 0.5em 1em -1em 0.5em;
  filter: grayscale(1);
  border: 1.5px var(--primary-text-color) solid;
  transform: perspective(1500px) rotateY(-15deg);

  transition: transform .5s ease-in-out, filter .5s ease-in-out, box-shadow .5s ease-in-out;
  
}

#bio-pic img:hover {
  filter: none;
  transform: perspective(1500px) rotateY(-20deg);
  box-shadow: 10px 10px 0 0px var(--primary-color), 10px 10px 0 1px var(--accent-color);
}


#pic-border {
  position: relative;
  display: block;
  top: -95%;
  left: 5%;
  width: 100%;
  height: 100%;
  border: 1px var(--accent-color) solid;
  border-radius: 1em;
  z-index: 100;
}

#contact {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-content: start;
  height: 100vh;
}
#contact h2 {
  margin-top: 2vh;
}

#cta p, #cta h3 {
  width: 100%;
}

#cta {
  padding-top: 5vh;
  text-align: center;
}

#cta h3 {
  font-size: 4em;
}



/* #contact h2 {
  width: 100%;
  text-align: end;
} */

/* #cta {
  width: 100%;
  align-self: start;
  text-align: start;
} */

#email {
  width: '100%';
  display: flex;
  justify-content: center;

}

.link-button {
  width: 30%;
  margin: 2em;
  display:flex;
  justify-content: center;
  align-items: center;
  border: 1px var(--primary-text-color) solid;
  color: var(--accent-color);
  /* box-shadow: 5px 5px 0px var(--accent-color); */
  background-color: transparent;
  height: 5em;

  border-radius: 0.25em;
  animation: float 1s alternate infinite;

  transition: box-shadow .5s ease-in-out, transform .5s ease-in-out;
}

.link-button:hover {
  opacity: 0.8;
  box-shadow: -10px 10px 0 0px var(--primary-color), -10px 10px 0 1px var(--accent-color);  
  transform: perspective(1500px) rotateY(15deg);  

}


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {

  html {
    font-size: .75em;
    overflow-x: hidden;
  }

  h1 {
    font-size: 13vw;
  }

  h2 {
    font-size: 5vw;
  }
  
  h3, h4, p {
    font-size: 1em;
  }

  li {
    font-size: 1.4em;
  }
 

  .App{
    padding: 0 10vw 0 10vw;
  }

  #home {
    height: 110vh;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  #title{
    width: 100%;
  }

  #scroll-down::before {
    content: '🠗 '
  }

  #light-bulb {
    height: 20vh;
    right: 10vw;
  }

  #light-bulb::before {
    content: '';
    display: block;
    position: relative;
    top: 100%;
    right: .15em;
    width: .3em; 
    height: .75em; 
    background: var(--secondary-text-color);
    border-radius: 1em;
  
    animation: rotate 1s alternate infinite ease-in-out;
  }
  
  #light-bulb::after {
    content: '';
    display: block;
    position: relative;
    top: 98%;
    right: .35em;
    width: .75em; 
    height: .75em; 
    background: var(--primary-text-color);
    border-radius: 1em;
    box-shadow: 0px 0px 50px white;
  }

  .side-info {
    display: none;
  }

  .socials {
    display: flex;
    align-self: center;
    justify-content: center;
  }

  .arrow {
    display: none;
  }

  #nav-bar{
    position: absolute;
    justify-content: center;
  }

  #nav {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  #nav ul {
    display: none;
    display:flex;
    padding: 0;
    font-size: 1em; 
  }

  .flex-container{
    flex-flow: row wrap;
  }

  #work {
    
    width: 100%;
    margin: 0 0 40vh 0;
    font-size: .6em;
  }

  #work h3 {
    font-size: 1.25em;
  }

  #work-display {
    width: 99%;
    height: 0vh;
   
  }

  .canvas {
    display: none;
  }

  #work-info {
    justify-self: center;
    border: none;
    width: 100%;
    
  }

  .work-info-grid {
    display: flex;
    flex-flow: row wrap;
    align-content: start;
    height: 70vh;
    margin: 0em;
    padding-left: 4em;
    width: 80%;
  }

  #work-info-img {
    width: 100%;
    margin: 2em;
  }

  #work-info-desc {
    padding: 0em;
    width: 100%;
  }

  #work-selector {
    z-index: 1;
    width: 20%;
    display: none;
  }

  #work-info ul {
    width: 90%;
  }

  #about {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
  }

  #bio {
    width: 100%;
    padding: 0em;
  }

  #bio-pic-container {  
    width: 100%;
    height: 20vh;
    /* transform: rotate(-45deg); */
    padding: 0em;
    margin: 0em;
    display: none;
  }

  #bio-pic {
    width: 30%;
  }

  #skill-wrapper {
    padding: 0em;
    margin: 0em;
  }

  #skill-icons li {
    width: 1.5em;
    height: 1.5em;
  }

  #skill-icons {
    justify-content: center;
    width: 100%;
    padding: 0;
  }

  #contact {
    height: 87.5vh;
    display: flex;
    flex-flow: row wrap;
    text-align: center;
    align-content: start;
    font-size: 1em;
  }

  #cta h3 {
    font-size: 3em;
  }

  .scroll-link {
    font-size: 1em;
    width: 100%;
  }

  .link-button {
    width: 50%;
  }

}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  html {
    font-size: .5em;
    }

  .App{
    padding: 0 10vw 0 10vw;
  }  
  
  h1 {
    font-size: 10vw;
  }

  h2 {
    font-size: 4em;
  }

  h3, p, ul li {
    font-size: 1.5em;
  }

  #nav-bar {
    font-size: 1em;
  }

  h4 {
    font-size: 1em;
  }

  #bio, #skill-wrapper {
    padding: 0;
  }

  #bio-pic-container {
    display: none;
  }

  #work-info, #work-display, #title {
    width: 100%;
  }
  
  .arrow, .canvas, .side-info{
    display: none;
  }

  .work-container {
    display: block;
  }

#contact {
  height: 87.5vh;
}

#work {
  height: 100vh;
}
  #cta p, #cta h3 {
    width: 100%;

  }

  
  #work-info-img img { 
    width: 50%;
    justify-self: center;
  }

  .link-button {
    font-size: 2em;
    width: 50%;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 768px) {
  html {
    font-size: .7em;
    }

  .App{
    padding: 0 10vw 0 10vw;
  }  

  h1 {
    font-size: 10vw;
  }
  
  h2 {
    font-size: 4em;
  }

  h3,  p, li {
    font-size: 1.5em;
  }

  h4 {
    font-size: 1em;
  }

  #work-info {
    width: 100%;
  }

  #work-display {
    width: 80%;
  }

  #bio-pic-container {
    display: none;
  }
  
  .arrow {
    left: 5%;
  }

  .canvas {
    display: none;
  }

}


@media only screen and (min-width: 1200px) {
  html {
    font-size: .5em;
  }

  h1 {
    font-size: 6vw;
  }

  h2{
    font-size: 6em;
  }

  h3, p, li {
    font-size: 1.5em;
  }

  h4 {
    font-size: .9em;
  }

  .arrow {
    display: none;
  }

  .side-info {
    display: block;
    right: -30vw;
  }

  .canvas {
    display: block;
  }

  #work-display {
    width: 80%;
  }

  #work-info-img img {
    width: 70%;
  }

  .work-container {
    display: flex;
  }

  #bio-pic-container {
    display: flex;
  }

  .arrow, .side-info {
    display: block;
  }

  .side-info {
    right: -12vw;
  }

 

  #work-info-img img { 
    width: 65%;
  }

 

}

@media only screen and (min-width: 1920px) {
  html {
    font-size: .75em;
  }

  h1 {
    font-size: 8vw;
  }

  h2{
    font-size: 6em;
  }

  h3, p, li {
    font-size: 1.5em;
  }

  .App{
    padding: 0 15vw 0 15vw;
  }

  #work{
    padding-bottom: 10vh;
  }

  #work-display {
    height: 80vh;
  }

  .arrow {
    display: block;
  }

  .side-info {
    right: -15%;
  }

  .link-button {
    font-size: 2em;
    width: 50%;
  }
}


@media only screen and (min-width: 2500px) {
  html {
    font-size: 1em;
  }
}

@media only screen and (min-width: 3000px) {
  html {
    font-size: 1.25em;
  }
}

@media only screen and (min-width: 3500px) {
  html {
    font-size: 1.5em;
  }
}